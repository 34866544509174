

export default [
  {
    title: "",
    urls: './images/1.jpg',
  },
  {
    title: "",
    urls: './images/2.jpg',
  },
  {
    title: "",
    urls: './images/3.jpg',
  },
  {
    title: "",
    urls: './images/4.jpg',
  },
  {
    title: "",
    urls: './images/5.jpg',
  },
  {
    title: "",
    urls: './images/6.jpg',
  },
  {
    title: "",
    urls: './images/7.jpg',
  },
  {
    title: "",
    urls: './images/8.jpg',
  },
  
];
